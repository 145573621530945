import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faBell,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarTimes,
  faCircle,
  faCircleCheck,
  faCircleUp,
  faClock,
  faClone,
  faCommentDots,
  faCopy,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileImage,
  faFilePdf,
  faIdCard,
  faMap,
  faMinusSquare,
  faNewspaper,
  faPlusSquare,
  faQuestionCircle,
  faStar,
  faTimesCircle,
  faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';

library.add(
  faBell,
  faBuilding,
  faCalendarAlt,
  faCalendarCheck,
  faCalendarTimes,
  faCircle,
  faCircleCheck,
  faCircleUp,
  faClock,
  faClone,
  faCommentDots,
  faCopy,
  faEdit,
  faEnvelope,
  faEye,
  faEyeSlash,
  faFile,
  faFileAlt,
  faFileArchive,
  faFileImage,
  faFilePdf,
  faIdCard,
  faMap,
  faMinusSquare,
  faNewspaper,
  faPlusSquare,
  faQuestionCircle,
  faStar,
  faTimesCircle,
  faTrashAlt,
);
